import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './MachinesDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const MachinesDetails = () => {
  const { id } = useParams();
  const [tool, setTool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const powertoolsData = DataService.getPowertoolsAccessoriesData();
    const foundTool = powertoolsData
      .find(category => category.id === 'machines')?.items
      .find(item => item.uuid === id);

    // console.log('foundTool', foundTool);

    setTimeout(() => {  // Optional: Remove this if you don't want artificial delay
      setIsLoading(false);
      if (foundTool) {
        setTool(foundTool);
      }
    }, 1000);
  }, [id]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (!tool) {
    return (
      <ProductNotFound />
    );
  }

  return (
    <div className="machines-details">
      <h2 className="machines-details-title">Machine</h2>

      <div className="machines-details-container">
      <div className="machines-details-group">
          <h3>Model</h3>
          <p>{tool.id}</p>
        </div>

        <div className="machines-details-group">
          <h3>Description</h3>
          <p>{tool.description}</p>
        </div>
      </div>
    </div>
  );
};

export default MachinesDetails;