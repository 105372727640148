import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './CardDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const CardDetails = () => {
  const { id, type } = useParams();
  const [tool, setTool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const powertoolsData = DataService.getAutoSparePartsData()
    const foundTool = powertoolsData
      .find(category => category.id === type)?.items
      .find(item => item.BOSCH_PART_NO === id);

    // console.log('foundTool', foundTool);

    setTimeout(() => {  // Optional: Remove this if you don't want artificial delay
      setIsLoading(false);
      if (foundTool) {
        setTool(foundTool);
      }
    }, 500);
  }, [id, type]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (!tool) {
    return (
      <ProductNotFound />
    );
  }

  return (
    <div className="card-d-details">
      <h2 className="card-d-details-title">{tool.PART}</h2>

      <div className="card-d-details-container">

      <div className="card-d-details-group">
          <h3>Bosch Part Number</h3>
          <p>{tool.BOSCH_PART_NO || 'NA'}</p>
        </div>

        <div className="card-d-details-group">
          <h3>OE Part Number</h3>
          <p>{tool.OE_PART_NUMBER || 'NA'}</p>
        </div>

        <div className="card-d-details-group">
          <h3>Make</h3>
          <p>{tool.MAKE || 'NA'}</p>
        </div>

        <div className="card-d-details-group">
          <h3>External Part Number</h3>
          <p>{tool.EXTERNAL_PART_NUMBER || 'NA'}</p>
        </div>

        <div className="card-d-details-group">
          <h3>External Make</h3>
          <p>{tool.EXTERNAL_MAKE || 'NA'}</p>
        </div>

        <div className="card-d-details-group">
          <h3>Vehicle Application</h3>
          <p>{tool.VEHICLE_APPLICATION || 'NA'}</p>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;