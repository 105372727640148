import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './AccessoriesDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const AccessoriesDetails = () => {
  const { id } = useParams();
  const [tool, setTool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const powertoolsData = DataService.getPowertoolsAccessoriesData();
    const foundTool = powertoolsData
      .find(category => category.id === 'accessories')?.items
      .find(item => item.Part_Nr === id);

    setTimeout(() => {  // Optional: Remove this if you don't want artificial delay
      setIsLoading(false);
      if (foundTool) {
        setTool(foundTool);
      }
    }, 1000);
  }, [id]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (!tool) {
    return (
      <ProductNotFound />
    );
  }

  return (
    <div className="accessories-details">
      <h2 className="accessories-details-title">Accessory</h2>

      <div className="accessories-details-container">
        <div className="accessories-details-group">
          <h3>Model</h3>
          <p>{tool.Part_Nr}</p>
        </div>
        <div className="accessories-details-group">
          <h3>Description</h3>
          <p>{tool.Description}</p>
        </div>
      </div>
    </div>
  );
};

export default AccessoriesDetails;