// src/pages/DieselCenter/DieselCenter.js
import './DieselCenter.css';
import DieselCenterItems from './DieselCenterItems';

// src/pages/DieselCenter/DieselCenter.js
const DieselCenter = () => {

  const items = {
    'service': {
      title: 'Services',
      items: [
        {
          title: 'Injector',
          description: 'Detailed description for Item A. This can include multiple lines of text explaining ',
          image: 'images/diesel-center/services/injector.png',
          subcategories: [
            { id: 'c1', name: 'Cleaning' },
            { id: 'c2', name: 'Overhaul and repair' },
            { id: 'c3', name: 'Internal Polishing' },
            { id: 'c4', name: 'Testing' },
          ]
        },
        {
          title: 'Pump',
          description: 'Detailed description for Item B. This can include multiple lines of text explaining the service or product features.',
          image: 'images/diesel-center/services/pump.png',
          subcategories: [
            { id: 'c1', name: 'Cleaning' },
            { id: 'c2', name: 'Overhaul and repair' },
            { id: 'c3', name: 'Internal Polishing' },
            { id: 'c4', name: 'Testing' },
          ]
        },
      ]
    },
    'spares': {
      title: 'Spares',
      items: [
        {
          title: 'Nozzles',
          description: 'Detailed description for Item A. This can include multiple lines of text explaining the service or product features.',
          image: 'images/diesel-center/spares/nozzles.png'
        },
        {
          title: 'Plungers',
          description: 'Detailed description for Item B. This can include multiple lines of text explaining the service or product features.',
          image: 'images/diesel-center/spares/plungers.png'
        },
        {
          title: 'Pumps',
          description: 'Detailed description for Item C. This can include multiple lines of text explaining the service or product features.',
          image: 'images/diesel-center/spares/pumps.png'
        }
      ]
    }
  }


  return (
    <div className='dc-wrapper'>
      <div className="dc-container">
        <div className='dc-title'>
          <h1>DieselCenter</h1>
        </div>
      </div>
      <div className="dropdown-container">
        <DieselCenterItems items={items.service} />
        <DieselCenterItems items={items.spares} />
      </div>
    </div>
  );
}

export default DieselCenter;