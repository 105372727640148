import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './PowertoolsDetails.css';
import { DataService } from '../services/DataService';
import Loading from './Loading';
import ProductNotFound from './ProductNotFound';

const PowertoolsDetails = () => {
  const { id } = useParams();
  const [tool, setTool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const powertoolsData = DataService.getPowertoolsAccessoriesData();
    const foundTool = powertoolsData
      .find(category => category.id === 'powertools')?.items
      .find(item => item.model === id);

    // console.log('foundTool', foundTool);

    setTimeout(() => {  // Optional: Remove this if you don't want artificial delay
      setIsLoading(false);
      if (foundTool) {
        setTool(foundTool);
      }
    }, 1000);
  }, [id]);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (!tool) {
    return (
      <ProductNotFound />
    );
  }

  return (
    <div className="powertools-details">
      <h2 className="powertools-details-title">Powertool</h2>

      <div className="powertools-details-container">
        <div className="powertools-details-group">
          <h3>Model</h3>
          <p>{tool.model}</p>
        </div>

        <div className="powertools-details-group">
          <h3>Category</h3>
          <p>{tool.category}</p>
        </div>

        <div className="powertools-details-group">
          <h3>Description</h3>
          <p>{tool.description}</p>
        </div>

        {/* <div className="powertools-details-group">
          <h3>Technical Specifications</h3>
          <p>{tool.technicalSpecs}</p>
        </div> */}

        {tool.url && <div className="powertools-details-group">
          <h3>Product Information</h3>
          <div className="powertools-details-product-info">
            {/* <p><strong>EAN (2-Pin):</strong> {tool.eanUpc2Pin}</p>
            <p><strong>EAN (3-Pin):</strong> {tool.eanUpc3Pin}</p> */}
            <a
              href={tool.url}
              target="_blank"
              rel="noopener noreferrer"
              className="powertools-details-product-link"
            >
              View Product Page
            </a>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default PowertoolsDetails;