import React from 'react';
import './DieselCenterItems.css';

const DieselCenterItems = ({ items }) => {
  return (
    <div className="dci-services-container">
      <h1 className="dci-services-title">{items.title}</h1>

      <div className="dci-services-list">
        {/* Service Item A */}
        {items.items.map(item => (
          <div className="dci-service-item">
            <div className="dci-image-container">
              <img
                src={item.image}
                alt="Service A"
                className="dci-service-image"
              />
            </div>
            <div className="dci-content-container">
              <h2 className="dci-item-title">{item.title}</h2>
              <p className="dci-item-description">
                {item.description}
              </p>

              <ul className="subcategories-list">
                {item.subcategories && item.subcategories.map(subcategory => (
                  <li key={subcategory.id} className="subcategory-item">
                    {/* <BulletPoint /> */}
                    <span className="subcategory-text">{subcategory.name}</span>
                  </li>
                ))}
              </ul>

            </div>
          </div>
        ))}


        {/* Service Item B */}
        {/* <div className="dci-service-item">
          <div className="dci-image-container">
            <img 
              src="/api/placeholder/300/200"
              alt="Service B"
              className="dci-service-image"
            />
          </div>
          <div className="dci-content-container">
            <h2 className="dci-item-title">Item B</h2>
            <p className="dci-item-description">
              Detailed description for Item B. This can include multiple lines of text
              explaining the service or product features.
            </p>
          </div>
        </div> */}

        {/* Service Item C */}
        {/* <div className="dci-service-item">
          <div className="dci-image-container">
            <img 
              src="/api/placeholder/300/200"
              alt="Service C"
              className="dci-service-image"
            />
          </div>
          <div className="dci-content-container">
            <h2 className="dci-item-title">Item C</h2>
            <p className="dci-item-description">
              Detailed description for Item C. This can include multiple lines of text
              explaining the service or product features.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DieselCenterItems;