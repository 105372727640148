import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ part, type }) => {
  return (
    <div className="sp-card">
      <div className="sp-card-content">
        <div className="sp-card-header">
          <h3 className="sp-part-number">{part.PART}</h3>
          <p className="sp-bosch-number">Bosch: {part.BOSCH_PART_NO}</p>
        </div>
        
        <div className="sp-card-details">
          <div className="sp-detail-row">
            <span className="sp-label">Make:</span>
            <span className="sp-value">{part.MAKE || 'N/A'}</span>
          </div>
          
          <div className="sp-detail-row">
            <span className="sp-label">External Part:</span>
            <span className="sp-value">{part.EXTERNAL_PART_NUMBER || 'N/A'}</span>
          </div>
          
          <div className="sp-detail-row">
            <span className="sp-label">External Make:</span>
            <span className="sp-value">{part.EXTERNAL_MAKE || 'N/A'}</span>
          </div>

          <div className="sp-detail-row">
            <span className="sp-label">Vehicle:</span>
            <span className="sp-value vehicle-text">{part.VEHICLE_APPLICATION || 'N/A'}</span>
          </div>
        </div>
      </div>

      <Link to={`/parts-detail/${type}/${part.BOSCH_PART_NO}`} className="sp-read-more">
        View Details
      </Link>
    </div>
  );
};

export default Card;