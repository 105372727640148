import React from 'react';
import './Home.css';

const Home = () => {

  const products = [
    {
      id: 'powertools',
      title: 'Powertools',
      mainImage: '/images/home/powertools/powertools.webp',
      additionalImages: [
        {
          image: '/images/home/powertools/powertools-1.png',
          label: ''
        },
        {
          image: '/images/home/powertools/powertools-2.png',
          label: ''
        },
        {
          image: '/images/home/powertools/powertools-3.png',
          label: ''
        }
      ]
    },
    {
      id: 'machine',
      title: 'Machine',
      mainImage: '/images/home/machines/machines-2.webp',
      additionalImages: [
        {
          image: '/images/home/machines/machines-1.webp',
          label: ''
        },
        {
          image: '/images/home/machines/machines-4.webp',
          label: ''
        },
        {
          image: '/images/home/machines/machines-3.webp',
          label: ''
        }
      ]
    },
    {
      id: 'accessories',
      title: 'Accessories',
      mainImage: '/images/home/accessories/accessories.png',
      additionalImages: [
        {
          image: '/images/home/accessories/accessories-1.png',
          label: ''
        },
        {
          image: '/images/home/accessories/accessories-2.png',
          label: ''
        },
        {
          image: '/images/home/accessories/accessories-3.png',
          label: ''
        }
      ]
    },
    {
      id:'spares',
      title: 'Spares',
      mainImage: '/images/home/spares/spares-2.webp',
      additionalImages: [
        {
          image: '/images/home/spares/spares-1.png',
          label: ''
        },
        {
          image: '/images/home/spares/spares-3.png',
          label: ''
        },
        {
          image: '/images/home/spares/spares-4.png',
          label: ''
        }
      ]
    }
    // ... add other products similarly
  ];

  const services = [
    {
      id: 'spare',
      title: 'Spare Parts',
      mainImage: '/images/home/dc/s/spares-3.webp',
      hoverImages: [
        '/images/home/dc/s/spares-5.webp',
        '/images/home/dc/s/spares-2.webp',
        '/images/home/dc/s/spares-4.webp'
      ]
    },
    {
      id: 'service',
      title: 'Services',
      mainImage: '/images/home/dc/dc-2.webp',
      hoverImages: [
        '/images/home/dc/dc-1.webp',
        '/images/home/dc/main.jpeg',
        '/images/home/dc/dc-3.webp'
      ]
    }
  ];

  return (
    <div className='home-wrapper'>
      <div className="home-container">
        <div className='company-info'>
          <h1>Welcome to Parts Xpress</h1>
          <p className="company-description">
          Part Xpress Limited is a leading provider of top-quality automotive parts, diesel services, power tools with accessories, and a wide range of light machinery. We are committed to delivering exceptional products and services to our customers, ensuring their needs are met with the highest standards of quality, reliability, and professionalism
          </p>
        </div>
      </div>

      <div className="home-container">
        <div className='company-info'>
          <h1>Our Products</h1>
          <div className='home-card-container'>
            {products.map(product => (
              <div key={product.id} className='home-card'>
                <img 
                  src={product.mainImage} 
                  alt={product.title} 
                  className="home-card-image" 
                />
                <div className="additional-images">
                  {product.additionalImages.map((img, index) => (
                    <div key={index} className="image-wrapper">
                      <img src={img.image} alt={`${product.title} ${index + 1}`} />
                      <span className="image-label">{img.label}</span>
                    </div>
                  ))}
                </div>
                <p className="home-card-label">{product.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className='company-info'>
          <h1>Diesel Center</h1>

          <div className="services-container">
            {services.map((service) => (
              <div key={service.id} className="service-card">
                <div className="main-image-container">
                  <img
                    src={service.mainImage}
                    alt={service.title}
                    className="main-image"
                  />
                  <div className="overlay">
                    <div className="content">
                      <h2>{service.title}</h2>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>

                <div className="hover-images">
                  {service.hoverImages.map((img, index) => (
                    <div key={index} className="hover-image-container">
                      <img
                        src={img}
                        alt={`${service.title} preview ${index + 1}`}
                        className="hover-image"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>



      <div className="home-container">
        <div className='company-info'>
          <h1>Why Choose Us?</h1>
          <div className='choose-us-box'>
            <div className='choose-us-card'>
              <img src="/images/icons/inventory.png" alt="Inventory Icon" className="choose-us-icon" />
              <h3>Extensive Inventory</h3>
              {/* <p>
                Choose from thousands of car parts for both passenger and commercial vehicles.
              </p> */}
            </div>
            <div className='choose-us-card'>
              <img src="/images/icons/trusted-quality.png" alt="Quality Icon" className="choose-us-icon" />
              <h3>Trusted Quality</h3>
              {/* <p>
                Our parts are sourced from leading manufacturers, ensuring reliability and performance.
              </p> */}
            </div>
            <div className='choose-us-card'>
              <img src="/images/icons/customer-support.png" alt="Support Icon" className="choose-us-icon" />
              <h3>Customer Support</h3>
              {/* <p>
                Get expert assistance from our knowledgeable team to find the right part.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
