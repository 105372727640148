// src/components/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <h2>Contact Us</h2>
      <div className='footer-details'>
      {/* <p><strong>Address:</strong></p> */}
      <p>Plot No. 21, Vingunguti Industrial Area, <br/>Nyerere Rd, Dar es Salaam, Tanzania</p>
      {/* <p><strong>Phone:</strong> +123 456 7890</p> */}
      {/* <p><strong>Email:</strong></p> */}
      <p><strong>sales@partsxpress.co.tz</strong></p>
      </div>

      <p className='rights-reserved'>© 2024 Parts Xpress. All rights reserved.</p>
    </footer>
  );
}

export default Footer;