import React from "react";
import "./ProductNotFound.css";

const ProductNotFound = () => {
  return (
    <div className="product-not-found">
      <div className="product-not-found-content">
        <h2>Product Not Found</h2>
        <p>Sorry, we couldn't find the product you're looking for.</p>
        <p>Please check the product ID and try again.</p>
      </div>
    </div>
  );
}

export default ProductNotFound;